@use "../common/variables";
@use "../common/breakpoints";

%common-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.outer-container {
  width: auto;
}

.inner-container {
  max-width: 1100px;
  margin: 0 auto;

  @include breakpoints.px1080 {
    padding: 0 1.5rem;
    max-width: 510px;
  }

  &__grid {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 1rem;
    padding: 4rem 0;

    & > svg,
    & > img {
      margin-left: auto;
      margin-right: auto;

      @include breakpoints.px1080 {
        margin: 1.25rem auto 0 auto;
        display: block;
        max-width: 280px;
      }
    }

    &[data-is-grid-layout="3/1"] {
      grid-template-columns: 3fr 1fr;
    }

    &[data-is-grid-layout="2/1"] {
      grid-template-columns: 2fr 1fr;
    }

    &[data-is-grid-layout="1/1"] {
      grid-template-columns: 1fr 1fr;
    }

    &[data-is-grid-layout="auto"] {
      grid-template-columns: auto;
    }

    @include breakpoints.px1080 {
      padding: 2rem 0;
      display: block;
    }
  }
}

.home-page-heading {
  font-weight: 500;
  font-size: 2.625rem;
  font-family: "Chillax-Medium", sans-serif;

  @include breakpoints.px1080 {
    font-size: 1.563rem;
  }

  & > span {
    display: inline-block;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background-color: variables.$color-light-orange;
      border-radius: variables.$border-radius-8px;
      height: 5px;
      bottom: 0;
      right: 0;
      left: 0;

      @include breakpoints.px1080 {
        height: 3px;
      }
    }
  }
}

.home-page-para {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  margin-top: 1.5rem;

  @include breakpoints.px1080 {
    margin-top: 1rem;
    font-size: 1rem;
  }

  &[data-is-attr="bold"] {
    font-weight: 500;
  }

  &[data-is-attr="list"] {
    margin-top: 0.75rem;
    list-style-position: inside;

    @include breakpoints.px1080 {
      margin-top: 0.5rem;
    }
  }

  &[data-is-attr="styled"] {
    max-width: 1032px;
    border-radius: 0.75rem;
    padding: 0.75rem 1.25rem;
    margin: auto;
    font-size: 1.125rem;
    margin-top: 0.75rem;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    gap: 1rem;

    @include breakpoints.px1080 {
      font-size: 0.938rem;
    }

    &:first-of-type {
      margin-top: 1.25rem;
    }
  }

  a {
    text-decoration: none;
    font-weight: 600;
  }
}

.home-page-link {
  margin-top: 1.5rem;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  background: #fff;
  font-family: "Chillax-Medium", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  color: #3c0061;
  text-decoration: none;
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;

  @include breakpoints.px1080 {
    margin-top: 1.25rem;
    width: 100%;
    font-size: 1rem;
  }
}

.hero-section {
  &__inner {
    padding: 5rem 0 6.25rem 0;
    position: relative;

    @include breakpoints.px1080 {
      padding: 2rem 0;
    }

    & > img {
      position: absolute;
      right: 0;
      transform: translate(3em, 0);

      @include breakpoints.px1080 {
        position: static;
        transform: unset;
        width: 100%;
        margin-top: 1.25rem;
        max-width: 100%;
      }
    }
  }

  &__intro {
    &__lower {
      margin-top: 1.875rem;
      line-height: 1;

      @include breakpoints.px1080 {
        margin-top: 1.25rem;
      }
    }

    &__heading {
      font-size: 2.625rem;
      font-family: "Chillax-Medium", sans-serif;
      color: #160323;

      @include breakpoints.px1080 {
        font-size: 1.563rem;
      }
    }

    &__sub-heading {
      font-size: 1.375rem;
      font-family: "Poppins", sans-serif;
      margin-top: 2rem;
      font-weight: 500;
      color: #3c0061;

      @include breakpoints.px1080 {
        font-size: 0.813rem;
        margin-top: 1.125rem;
      }

      & > span {
        position: relative;
        display: inline-block;

        &::after {
          position: absolute;
          content: "";
          height: 5px;
          bottom: -0.375em;
          right: 0;
          left: 0;
          background: #fd5201;
          border-radius: 0.5rem;
          transform: translateX(0.5rem);

          @include breakpoints.px1080 {
            height: 3px;
            transform: unset;
            border-radius: 5px;
          }
        }
      }
    }

    &__icons {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1.875rem;
      justify-content: flex-start;

      & > div {
        padding: 0.75rem;
        display: flex;
        cursor: pointer;

        @include breakpoints.px1080 {
          padding: 0.5rem;
        }
      }

      @include breakpoints.px1080 {
        margin: 0;
        gap: 0.5rem;
        justify-content: center;
      }
    }

    &__invite {
      margin-top: 1.25rem;
      font-size: 1.25rem;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: #767676;

      @include breakpoints.px1080 {
        font-size: 0.813rem;
        margin-top: 0.75rem;
        text-align: center;
      }
    }
  }
}

.no-investor {
  @extend %common-background;
  background-size: contain;
  background-position: right;
  background-image: url("../../assets/no-investor.png");

  @include breakpoints.px1080 {
    background-image: url("../../assets/no-investor-small.png");
    background-size: cover;
    background-position: center;
  }

  &::after {
    content: "";
    background-image: url("../../assets/linear-bg-small.png");
    @extend %common-background;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    @include breakpoints.px1080 {
      background-image: url("../../assets/linear-investor-small.png");
    }
  }

  &__inner {
    position: relative;
    z-index: 9;
    padding-bottom: 6.25rem;

    @include breakpoints.px1080 {
      padding-bottom: 11rem;
    }

    .home-page-heading,
    .home-page-para {
      color: variables.$color-white;

      a {
        color: #fff;
      }
    }
  }
}

.discord {
  background-color: variables.$color-blue;

  &__wrapper {
    position: relative;
    padding: 4.25rem;

    @include breakpoints.px1080 {
      padding: 4.25rem 0;
    }

    & > svg {
      position: absolute;
      right: 2.75em;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__join {
    display: flex;
    justify-content: center;

    & > a {
      border: 2px solid variables.$color-white;
      color: variables.$color-white;
      font-size: 1.25rem;
      font-family: "Chillax-Medium", sans-serif;
      border-radius: variables.$border-radius-8px;
      text-decoration: none;
      padding: 0.5rem 0.75rem;
      max-width: 346px;
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 1.9rem;
      transition: 0.3s background-color linear;

      &:hover {
        background-color: #7984f5;
      }

      @include breakpoints.px1080 {
        font-size: 1rem;
      }

      @include breakpoints.px330 {
        gap: 1rem;
      }
    }
  }
}

.acronym-banner {
  background: #eae4d9;

  p {
    color: #330320;
    padding: 2rem 0;
    font-size: 2rem;
    font-family: "Chillax-Medium", sans-serif;
    font-weight: 500;
    text-align: center;
    line-height: 1;

    @include breakpoints.px1080 {
      line-height: 1.4;
      text-align: left;
      color: #3c0061;
    }

    span {
      color: variables.$color-light-orange;
    }
  }
}

.section-purple {
  background: #3c0061;

  &__inner {
    .home-page-heading {
      color: #fff;
    }

    .home-page-para {
      color: #fff;

      a {
        color: #d591ff;
      }
    }
  }
}

.section-lavender {
  background: #e3dff1;

  &__inner {
    .home-page-heading {
      color: #3c0061;
    }

    .home-page-para {
      color: #444444;
    }
  }
}

.section-white {
  background: #fff;

  &__inner {
    .home-page-heading {
      color: #3c0061;
    }

    .home-page-para {
      color: #444444;

      &[data-is-attr="styled"] {
        background: #f6f5fb;
        color: #55475f;
      }
    }
  }
}

.expanding-verifications {
  .home-page-heading {
    & > span {
      &::after {
        width: 85%;
      }
    }
  }

  & > div {
    margin-top: -5rem;

    @include breakpoints.px1080 {
      margin-top: 0;
    }
  }

  & > img {
    margin-top: 3rem;

    @include breakpoints.px1080 {
      margin-top: 2rem;
    }
  }
}

.based-in {
  & > div {
    margin-top: -1rem;

    @include breakpoints.px1080 {
      margin-top: 0;
    }
  }

  & > img {
    margin-top: 1rem;

    @include breakpoints.px1080 {
      margin-top: 2rem;
    }
  }
}

.coming-soon {
  & > svg {
    margin: 0 auto;

    @include breakpoints.px1080 {
      margin: 3rem auto 3rem auto;
    }
  }

  &__links {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1.25rem;

    @include breakpoints.px1080 {
      margin-top: 1.25rem;
      flex-direction: column;
    }

    .home-page-link {
      margin: 0;
      flex-grow: 1;
    }
  }
}

@mixin labelAfter($file) {
  label {
    &::after {
      position: absolute;
      content: url("../../assets/#{$file}");
      left: 0.5em;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.join-waitlist {
  .wait-list-modal {
    margin-top: 1.5rem;
    padding: 1rem;
    border-radius: 0.75rem;
    background: #c6bee352;
    border: 1px solid #d9d4ed;
    width: 100%;

    @include breakpoints.px1080 {
      margin-top: 1.25rem;
    }

    &__content {
      margin: 0;

      form {
        flex-direction: row;
        align-items: flex-start;
        gap: 0.5rem;

        @include breakpoints.px1080 {
          flex-direction: column;
          gap: 0.5rem;
        }

        & > * {
          flex-basis: 100%;
          width: 100%;
        }

        & > div {
          &:nth-child(1) {
            @include labelAfter("name-input.png");
          }

          &:nth-child(2) {
            @include labelAfter("email-input.png");
          }

          &:nth-child(3) {
            @include labelAfter("handle-input.png");
          }
        }
      }

      label {
        position: relative;

        input {
          margin: 0;
          border: 1px solid #d9d4ed;
          background: #fff;
          padding: 0.5rem 0.75rem 0.5rem 2.5rem;
          font-size: 1rem;
          color: #767676;

          &::placeholder {
            font-style: normal;
            color: #767676;
          }
        }
      }

      .submit-button-wrapper {
        border: 0;
        margin: 0 0 0 0.5rem;
        padding: 0;
        flex-basis: content;

        @include breakpoints.px1080 {
          margin: 0;
        }
      }

      .wait-list-modal__content__submit {
        padding: 0;
        width: 100%;
        height: 43px;
        width: 6.25rem;
        flex-basis: 100%;
        font-size: 1rem;
      }
    }
  }

  @include breakpoints.px1080 {
    & > img {
      margin-top: 2rem;
    }
  }
}
