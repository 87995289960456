@use "../common/variables";
@use "../common/common";
@use "../common/breakpoints";

.roadmap {
  // &__content {
  //   margin: 1.125rem 0 5rem 0;

  //   p {
  //     @extend %common-text-style;
  //     font-size: 1rem;
  //     color: variables.$color-dark-grey;
  //   }

  //   div {
  //     div {
  //       max-width: 680px;
  //       margin: 1.875rem auto;
  //       border-radius: variables.$border-radius-12px;
  //       border: 2px solid variables.$color-light-grey-4;
  //       box-shadow: 0 2px 6px 0 rgb(131 82 194 / 0.04);

  //       @include breakpoints.px767 {
  //         margin: 1.875rem 1.5rem 1.875rem 1.5rem;
  //       }

  //       em {
  //         color: variables.$color-dark-grey;
  //         font-weight: 300;
  //         font-style: normal;
  //       }

  //       p {
  //         background: variables.$color-purple;
  //         display: flex;
  //         justify-content: space-between;
  //         align-items: center;
  //         padding: 0.75rem 2.5rem;
  //         border-radius: variables.$border-radius-12px;
  //         margin: 0;
  //         font-weight: 500;
  //         font-size: 1.125rem;
  //         color: variables.$color-white;
  //         font-family: "Chillax-medium", sans-serif;

  //         @include breakpoints.px767 {
  //           display: flex;
  //           flex-direction: column;
  //           gap: 1rem;
  //           padding: 0.75rem 1.5rem;
  //           font-size: 1rem;
  //         }

  //         span {
  //           &:first-of-type {
  //             @include breakpoints.px767 {
  //               display: block;
  //               width: 100%;
  //             }
  //           }

  //           &:last-of-type {
  //             display: flex;
  //             align-items: center;
  //             gap: 0.375rem;
  //             font-weight: 500;
  //             font-size: 1rem;
  //             color: #fafafa;
  //             margin: 0 0.625rem;
  //             font-family: "Poppins", sans-serif;

  //             @include breakpoints.px767 {
  //               font-size: 0.875rem;
  //               font-weight: 300;
  //               width: 100%;
  //               display: flex;
  //               align-items: center;
  //               justify-content: flex-end;
  //             }
  //           }
  //         }
  //       }

  //       ul {
  //         padding: 1rem 1.5rem;

  //         @include breakpoints.px767 {
  //           padding: 0.5rem 1.5rem;
  //         }

  //         li {
  //           list-style: none;
  //           padding: 0.5rem;
  //           border-radius: variables.$border-radius-8px;
  //           border: 1px solid variables.$color-purple;
  //           background: variables.$color-white-2;
  //           margin: 0.625rem 0;
  //           font-weight: 500;
  //           font-size: 1rem;
  //           text-align: center;
  //           color: variables.$color-light-black;
  //           font-family: "Poppins", sans-serif;

  //           &:last-of-type {
  //             margin-bottom: 1rem;
  //           }

  //           @include breakpoints.px767 {
  //             font-size: 0.875rem;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  &__content {
    margin: 1.125rem 0 5rem 0;

    p {
      @extend %common-text-style;
      font-size: 1rem;
      color: #767676;
    }

    h3 {
      @extend %common-policies-h3-style;
    }

    strong {
      font-size: 1.125rem;
      color: #767676;
    }

    ol,
    ul {
      @extend %common-text-style;
      font-size: 1rem;
      color: #767676;

      li {
        margin-left: 1.5rem;
      }
    }
  }
}
